<template>
  <div id="donation-site-page">
    <v-expand-transition>
      <div v-if="!finalPage" class="home-page">
        <v-form @submit.prevent="goNxtPage">
          <v-skeleton-loader type="list-item-three-line" v-if="themeLoading" :loading="true"></v-skeleton-loader>
          <v-row v-if="!themeLoading" align="center" justify="center" class="ma-0 text-center pa-0 text-h5 text-lg-h4 text-capitalize primary--text font-weight-light text-break">
            {{ themeModel.heading }}
          </v-row>
          <v-row v-if="!themeLoading" align="center" justify="center" class="ma-0 text-center my-2 text-body-2 text-lg-body-1 pa-0 primary--text text-capitalize font-weight-medium text-break">
            {{ themeModel.description }}
          </v-row>
          <v-card-title v-if="!paymentPage" class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-break justify-lg-start">
            {{ "Yes, Here's My Tax Deductible Gift to TheLight" | textCapts }}
          </v-card-title>
          <template v-if="!paymentPage">
            <v-row class="pa-0 ma-0 mb-5 mobile-page" align="center" justify="space-between">
              <v-col cols="12" class="col-lg-6 d-flex align-center justify-space-between pa-0 ma-0 pr-lg-3">
                <v-btn type="button" color="secondary" large class="w-49 font-weight-bold rounded-lg"
                       :outlined="model.frequency !== 'O'" @click="amountUpdate('O')">Give Once
                </v-btn>
                <v-btn type="button" color="secondary" large class="w-49 font-weight-bold rounded-lg"
                       :outlined="model.frequency !== 'M'" @click="amountUpdate('M')">Give Monthly
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" justify='start'>
              <v-col cols="12" class="col-lg-6 pa-0 ma-0 pr-lg-3">
                <div class="d-flex justify-space-between"
                     :class="{ 'error-message pa-3': submit && !model.amount }">
                  <template v-if="model.frequency === 'O'">
                    <div class="w-32 mb-3" v-for="(item, index) in themeModel.once_amount" :key="index">
                      <v-btn type="button" color="secondary" large class="font-weight-bold rounded-lg"
                             :outlined="btn_amount !== item.amount"
                             @click="dynamicAmtUpdate(item.amount)" block>${{ item.amount }}
                      </v-btn>
                      <div style="min-height: 30px" class="primary--text text-caption mt-1 text-center text-capitalize text-break" v-html="item.tag"></div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="w-32 mb-3" v-for="(item, index) in themeModel.recurring_amount"
                         :key="index">
                      <v-btn type="button" color="secondary" large class="font-weight-bold rounded-lg"
                             :outlined="btn_amount !== item.amount"
                             @click="dynamicAmtUpdate(item.amount)" block>${{ item.amount }}
                      </v-btn>
                      <div style="min-height: 30px"
                           class="primary--text text-caption mt-1 text-center text-capitalize text-break"
                           v-html="item.tag"></div>
                    </div>
                  </template>
                </div>
                <div v-if="submit && !model.amount" class="error--text">
                  Amount is required
                </div>
              </v-col>
              <v-col cols="12" class="col-lg-6 pa-0 ma-0 pl-lg-3">
                <div class="pa-0 ma-0 w-full mobile-page mb-5">
                  <div
                    class="other-amount rounded-lg d-lg-flex d-sm-flex d-md-flex d-block justify-space-between">
                    <div
                      class="left-box primary--text w-full font-weight-bold d-flex justify-center align-center">
                      {{ model.frequency !== 'O' ? 'My Monthly Gift' : 'My One-Time Gift' }}:
                    </div>
                    <div class="right-box w-full">
                      <v-text-field label="Other Amount" min="0" solo hide-details
                                    v-model.number="other_amount" @input="otherAmountChange" type="number"
                                    class="h-full box-shadow-none" @keydown="$helpers.avoidInputArrowKeyPress"
                                    @focus="$helpers.avoidInputScrollable">
                        <template v-slot:prepend-inner>
                          <div
                            class="primary--text w-15 ml-1 font-weight-bold pl-4 d-flex justify-center align-center">
                            $
                          </div>
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                  <div v-if="submit && !model.amount" class="error--text">
                    Amount is required
                  </div>
                </div>
              </v-col>
            </v-row>
            <DetailsForm :valid="$v" :submitForm="submit" @updateRefs="refsObj = { ...refsObj, ...$event }"/>
            <v-row>
              <v-col cols="12" class="col-lg-6 pb-0 ma-0 pl-lg-3">
                <vue-recaptcha ref="recaptcha" @expired="onVerify" @error="onVerify" @verify="onVerify"
                               :sitekey="recaptchaSiteKey"></vue-recaptcha>
                <div v-if="$v.model.recaptcha.$error" class="error--text">
                  Please verify recaptcha
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <PaymentForm v-if="paymentPage" :valid="$v" :submitForm="submit"
                         @updateRefs="refsObj = { ...refsObj, ...$event }" :stripe="stripe">
              <template slot="receiptName">
                <Input :label="model.payment_type === 'CC' ? `Card Holder's Name*` : 'Account Name*'"
                       :labelCls="{ 'error--text': $v.model.receipt_name.$dirty && !$v.model.receipt_name.required }">
                  <template v-slot:input>
                    <v-text-field name="Receipt Name" solo ref="receipt_name"
                                  class="box-shadow-none rounded-lg text-capitalize" color="primary"
                                  v-model="model.receipt_name"
                                  :error-messages="$helpers.errorMsg('name', $v.model.receipt_name, model.payment_type === 'CC' ? `Card Holder's Name` : 'Account Name')"
                                  hide-details="auto"
                                  :label="model.payment_type === 'CC' ? 'Name On Card' : 'Account Name'" outlined
                                  required/>
                  </template>
                </Input>
              </template>
            </PaymentForm>
          </template>
          <v-row class="mt-5" justify='start'>
            <v-col cols="12" class="col-lg-6 d-flex justify-center justify-lg-start align-center mobile-page">
              <v-btn type="submit" color="secondary" rounded large
                     class="w-full font-weight-bold process-donation" ref="submit_button"
                     :loading="intentLoading">
                <template v-slot:loader>
                  <v-progress-circular indeterminate :size="20" color="white"></v-progress-circular>
                  <span class="ml-2">Loading...</span>
                </template>
                {{
                  !paymentPage ? 'next' : `Process $${model.amount} ${model.frequency === 'O' ? 'one-time' : '/ monthly'} donation`
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-5" v-if="paymentPage && (themeModel.tax_deduction_notes || defaultTaxNotes)">
            <v-col cols="12" class="col-lg-6 mobile-page">
              <v-alert density="compact" title="Tax Deduction Notes" class="text-subtitle-2" icon="mdi mdi-information-variant-circle-outline" type="primary">
                {{ themeModel.tax_deduction_notes || defaultTaxNotes }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-expand-transition>
    <v-expand-x-transition>
      <Finalpage v-if="finalPage" class="transition-fast-in-fast-out v-card--reveal" @goHome="goNxtPage"/>
    </v-expand-x-transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import {VueRecaptcha} from 'vue-recaptcha';

import {Input} from '@/components';

export default {
  components: {
    VueRecaptcha,
    Input,
    DetailsForm: () => import("@/pages/dashboard/component/DetailsForm"),
    PaymentForm: () => import("@/pages/dashboard/component/PaymentForm"),
    Finalpage: () => import("@/pages/dashboard/component/FinalProcess"),
  },
  name: "Home",
  validations() {
    const valid = {
      model: {
        first_name: { required },
        last_name: { required },
        address1: { required },
        phone: { required },
        suburb: { required },
        email: { email, required },
        postcode: { required },
        state: { required },
        country: { required },
        recaptcha: { required }
      }
    };
    if (this.model.details_type === 'business') {
      valid.model['sf_account_id'] = { required };
    }
    if (this.paymentPage) {
      valid.model['receipt_name'] = { required };
    }
    return valid;
  },
  data: (vm) => ({
    recaptchaSiteKey: window.VUE_APP_GOOGLE_SITE_KEY,
    finalPage: false,
    btn_amount: null,
    other_amount: null,
    submit: false,
    stripe: null,
    amountDisabled: true,
    refsObj: {},
    themeModel: {
      heading: 'Thanks For Giving To Keep TheLight Shining!',
      description: "Your Amazing Gift Helps Spread Words Of Life, Hope, & Encouragement To Families All Over Melbourne! You Are Making A Difference - Thank You For Supporting Your 89.9 TheLight",
      frequency: 'O',
      once_amount: [{ amount: 200, tag: '', default: 0 }, { amount: 400, tag: '', default: 1 }, { amount: 600, tag: '', default: 0 }],
      recurring_amount: [{ amount: 200, tag: '', default: 0 }, { amount: 400, tag: '', default: 1 }, { amount: 600, tag: '', default: 0 }]
    },
    defaultTaxNotes: null,
    paymentPage: false,
    intentLoading: false,
  }),
  computed: {
    ...mapGetters(["themeApiModel", "themeLoading", "currentCampaign"]),

    paymentModel: {
      get() {
        return this.$store.getters["paymentModel"];
      },
      set(newValue) {
        return this.$store.commit("updatePaymentModel", newValue);
      },
    },
    model: {
      get() {
        return this.$store.getters["model"];
      },
      set(newValue) {
        return this.$store.commit("updateModel", newValue);
      },
    },
  },
  watch: {
    themeApiModel: function (newValue) {
      if (newValue) {
        const valueThemeModel = this.$helpers.getValueOnlyObj(newValue);
        this.themeModel = { ...this.themeModel, ...valueThemeModel };
        this.amountUpdate(this.themeModel.default_donate_mode);
      }
    },
    currentCampaign: function (newValue) {
      if (newValue && this.model.frequency === 'M') {
        this.model.sf_campaign_id = newValue ? newValue.sf_campaign_monthly : null;
      } else {
        this.model.sf_campaign_id = newValue && this.model.frequency === 'O' ? newValue.sf_campaign_once : null;
      }
    },
    paymentPage(value) {
      if (value && !this.model.receipt_name) {
        this.model.receipt_name = `${this.model.first_name} ${this.model.last_name}`;
      }
    }
  },
  filters: {
    textCapts: function (text) {
      if (text) {
        const caseWords = (text.trim()).split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
        return caseWords.replace('Thelight', 'TheLight');
      }
      return '';
    }
  },
  created() {
    this.getLatestIncentive({ with_variation: 1 });
    this.getOptionsData('DEFAULT_TAX_NOTES').then((res) => {
      this.defaultTaxNotes = res.option_value;
    }).catch(() => {});

    const valueThemeModel = this.$helpers.getValueOnlyObj(this.themeApiModel);
    this.themeModel = { ...this.themeModel, ...valueThemeModel };
    if (this.$helpers.getCurData("curDonate")) {
      this.finalPage = true;
    }
    this.amountUpdate(this.themeModel.default_donate_mode);
  },
  mounted() {
    this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
  },
  methods: {
    ...mapActions(["donatePaymentAmount", "StoreWthCentreSave", "getLatestIncentive", "createPaymentIntent", "createSubscription",
      "updateCardDetails", "getOptionsData"]),

    otherAmountChange() {
      if (this.other_amount && this.other_amount > 0) {
        this.btn_amount = 0;
        this.model.amount = this.other_amount;
      } else {
        this.other_amount = null;
        const defaultOnceAmt = this.themeModel.once_amount.find(item => item.default == 1),
          defaultRecurrAmt = this.themeModel.recurring_amount.find(item => item.default == 1)
        this.btn_amount = this.model.amount = this.model.frequency !== 'O' ? defaultRecurrAmt.amount : defaultOnceAmt.amount;
      }
    },
    onVerify: function (response) {
      if (response) this.model.recaptcha = response;
      else this.model.recaptcha = null;
    },
    goNxtPage() {
      if (this.paymentPage) this.onProcessPayment();

      if (!this.paymentPage && !this.finalPage) {
        const payload = {
          amount: this.model.amount,
          name: `${this.model.first_name} ${this.model.last_name}`,
          email: this.model.email,
          description: `${this.model.first_name} ${this.model.last_name} Single Charge`,
          first_name: this.model.first_name,
          last_name: this.model.last_name,
          phone: this.model.phone,
          address1: this.model.address1,
          suburb: this.model.suburb,
          state: this.model.state,
          postcode: this.model.postcode,
          recaptcha: this.model.recaptcha
        }
        if (this.model.frequency === 'M') {
          payload['start_date'] = this.$helpers.getMomentDatas('YYYY-MM-DD');
          payload['description'] = `${this.model.first_name} ${this.model.last_name} Monthly Gift`;
        }
        this.createIntent(payload, this.model.frequency === 'M' ? 'createSubscription' : 'createPaymentIntent');
      }

      if (this.finalPage) this.finalPage = !this.finalPage;
    },
    onProcessPayment() {
      this.submit = true;
      this.$v.$touch();
      if (this.$v.$invalid || !this.paymentModel.cardValidate || !this.model.amount) {
        this.$helpers.focusErrorElement(this.$v.model, this.refsObj);
        return;
      }
      this.confirmPaymentIntent(this.model.payment_type === 'CC' ? 'card' : 'au_becs_debit');
    },
    createIntent(payload, key) {
      this.$v.$touch();
      if (this.$v.$invalid || !this.model.amount) {
        this.$helpers.focusErrorElement(this.$v.model, this.refsObj);
        return true;
      }
      const self = this;
      this.intentLoading = true;
      this[key](payload).then(data => {
        self.model.client_secret = data.client_secret;
        self.model.setup_intent = data.setup_intent || false;  // value only only return future date from api
        if (data.customer_id) self.model.stripe_customer_id = data.customer_id;
        if (data.contact_id) self.model.contact_id = data.contact_id; // for new contact member
        if (data.subscription_id) this.model.subscription_id = data.subscription_id; // for monthly pay
        this.paymentPage = !this.paymentPage;
        self.intentLoading = false;
      }).catch(err => self.intentLoading = false);
    },
    confirmPaymentIntent(key) {
      this.$store.commit("toggleAppLoading");
      const fieldName = this.$helpers.titleCase(key.split('_').join(' '));
      const params3Dsecure = {
        return_url: `${window.location.origin}/3d-secure-completed`, //card
        setup_future_usage: 'off_session'
      }
      const confirmPaymentOptions = {
        payment_method: {
          [key]: key === 'card' ? this.paymentModel.cardNumber : this.paymentModel.auBankAccount,
          billing_details: { //sudebit
            name: `${this.model.first_name} ${this.model.last_name}`,
            email: this.model.email,
          },
        },
        ...!this.model.setup_intent ? params3Dsecure : {}
      };

      this.stripe[`confirm${fieldName.replace(/ +/g, '')}${this.model.setup_intent ? 'Setup' : 'Payment'}`](this.model.client_secret, confirmPaymentOptions)
        .then((result) => {
          this.$store.commit("toggleAppLoading");
          if (result.error) {
            this.$store.commit("snackbar/SHOW_MESSAGE", {
              text: result.error.message || 'Payment Failed',
              color: "error",
            });
          } else if (result.paymentIntent && result.paymentIntent.next_action) {
            const self = this;
            window.addEventListener('message', function (ev) {
              if (ev.data === '3DS-authentication-complete') {
                self.on3DSComplete();
              }
            }, false);
          } else {
            this.model.payment_intent_id = result[this.model.setup_intent ? 'setupIntent' : 'paymentIntent'].id;
            this.respStripeToken(result);
          }
        }).catch(err => this.$store.commit("toggleAppLoading"));
    },
    on3DSComplete() {
      const self = this;
      // Check the PaymentIntent
      this.stripe.retrievePaymentIntent(self.model.client_secret)
        .then(function (result) {
          if (result.error || (result.paymentIntent && result.paymentIntent.status === 'requires_payment_method')) {
            self.$store.commit("snackbar/SHOW_MESSAGE", {
              text: `Payment Failed!.`,
              color: "error",
            });
          } else self.respStripeToken(result);
        });
    },
    respStripeToken(result) {
      if (result.error) {
        this.paymentModel.stripeErr = result.error.message;
        this.$store.commit("toggleAppLoading");
      } else {
        this.paymentModel.stripeErr = '';
        this.$store.commit("toggleAppLoading");
        this.donatePayment();
      }
    },
    donatePayment() {
      this.model.campaign_id = this.currentCampaign && this.currentCampaign.id ? this.currentCampaign.id : null;
      const slug = this.$route.path.indexOf('/') !== -1 ? this.$route.path.slice(1) : null;
      if (slug) this.model.referer_slug = slug;
      const payload = this.model;
      delete payload.recaptcha;
      this.donatePaymentAmount(payload).then((response) => {
        if (!response || !response.data) {
          return;
        }
        const resData = response.data.data || null;
        if (resData && resData.status !== 0) {
          this.$helpers.lsPush("curDonate", resData.id);
          this.finalPage = true;
          if (window.VUE_APP_ENV == 'production') this.gtagTrigger(resData);
        }
      }).catch(() => {
        this.paymentPage = false
      });
    },
    gtagTrigger(donateModel) {
      gtag("ecommerce", "purchase", {
        'actionField': {
          'id': donateModel.id,
          'affiliation': 'Web Donation Form',
          'revenue': donateModel.amount,
        }
      });
      gtag("event", "purchase", {
        affiliation: "Web Donation Form",
        currency: "AUD",
        transaction_id: donateModel.id,
        value: donateModel.amount,
        items: [
          {
            id: donateModel.frequency === 'M' ? 'Recurring' : 'Once',
            name: donateModel.frequency === 'M' ? 'Recurring Donation' : 'Donation',
            quantity: 1,
            price: donateModel.amount
          }
        ]
      });
    },
    amountUpdate(freq = 'O') {
      this.other_amount = null;
      this.model.frequency = freq;
      const once_default_amt = this.themeModel.once_amount.filter(item => item.default == 1),
        recurring_default_amt = this.themeModel.recurring_amount.filter(item => item.default == 1);
      if (freq === 'M') {
        this.model.amount = this.btn_amount = recurring_default_amt.length > 0 ? recurring_default_amt[0].amount : this.themeModel.recurring_amount[0].amount;
        this.model.sf_campaign_id = this.currentCampaign ? this.currentCampaign.sf_campaign_monthly : null;
      } else {
        this.model.amount = this.btn_amount = once_default_amt.length > 0 ? once_default_amt[0].amount : this.themeModel.once_amount[0].amount;
        this.model.sf_campaign_id = this.currentCampaign ? this.currentCampaign.sf_campaign_once : null;
      }
    },
    dynamicAmtUpdate(amt) {
      this.model.amount = this.btn_amount = amt;
      this.other_amount = null;
    }
  }
};
</script>
